// @flow

import { MauticContact } from '../api/mautic/src';
import fetchContactData from '../shared/api-contact.service';
import { buildContactDisplayValues } from '../shared/contact.service';
import { getMauticIdFromUrl } from '../shared/utils';

export default class CardFromUrl {
  //   static icon: string;

  static init(t: any, options: any) {
    // options.url has the url in question
    // if we know cool things about that url we can give Trello a name and desc
    // to use when creating a card. Trello will also automatically add that url
    // as an attachment to the created card
    // As always you can return a Promise that resolves to the card details

    return new Promise((resolve) => {
      const contactId = getMauticIdFromUrl(options.url);
      return fetchContactData(t, contactId).then((contact: MauticContact) => {
        if (!contact || !contact.fields || !contact.fields.all) {
          throw t.NotHandled();
        }
        const display = buildContactDisplayValues(contact);
        return resolve({ name: display.label, desc: display.attribution });
      });
    });

    // if we don't actually have any valuable information about the url
    // we can let Trello know like so:
    // throw t.NotHandled();
  }
}
