/**
 * Handle all display tasks related to contacts (for api see api-contact.service.js )
 */

import { ContactFieldsAll, MauticContact } from '../api/mautic/src';

/**
 * Render an iso date string to a human readable format
 *
 * @param {string} date iso date string
 * @link https://reactgo.com/format-date-time-javascript/
 */
export function renderDateLabel(date) {
  if (!date) {
    return null;
  }
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);

  // @todo localize Today and Yesterday strings
  if (date.toLocaleDateString() === today.toLocaleDateString()) {
    return 'Today';
  }
  if (date.toLocaleDateString() === yesterday.toLocaleDateString()) {
    return 'Yesterday';
  }
  return date.toLocaleDateString('de-CH', {
    day: 'numeric',
    month: 'short',
  });
}
export function renderDate(date, day = 'numeric', month = 'short') {
  if (!date) {
    return null;
  }

  return date.toLocaleDateString('de-CH', {
    day,
    month,
  });
}
/**
 * Combine MauticContact values together to be displayed throughout the app
 */
export function buildContactDisplayValues(contact: MauticContact) {
  if (!contact || !contact.fields || !contact.fields.all) {
    return {
      label: '',
      attribution: '',
    };
  }
  const fields: ContactFieldsAll = contact.fields.all;
  const company = fields.company ? `(${fields.company})` : '';
  const label = `${fields.firstname || ''} ${fields.lastname || ''} ${company}`;

  let attributionDate = renderDate(fields.attribution_date);
  if (attributionDate) {
    attributionDate = ` (${attributionDate})`;
  }
  const attribution = `Attribution: ${fields.attribution || ''}${attributionDate}`;

  return {
    label,
    attribution,
  };
}
