import settings from './settings';

export function clearCache(t) {
  if (t.memberCanWriteToModel('card')) {
    t.remove('card', 'shared', 'cache');
  }
}

export function getCachedData(t, key) {
  return t.get('card', 'shared', 'cache').then((cache) => {
    if (!cache) {
      // nothing cached at the moment
      return null;
    }

    // this card does have mautic attachment url and has some cached data
    if (!cache.expires || cache.expires <= Date.now()) {
      // the cached data has expired, we shouldn't use it
      // console.debug('cache expired');
      clearCache(t);
      return null;
    }
    // the cache is still relevant (hasn't expired yet)
    if (!cache.key || cache.key !== key) {
      // the url we have cached doesn't match the url of the card
      clearCache(t);
      return null;
    }
    // everything checks out, we have good cached data we can use
    return cache.data;
  });
}
export function cacheToCard(t, key, data) {
  // we can only cache it if the current Trello member has write access
  if (t.memberCanWriteToModel('card')) {
    t.set('card', 'shared', {
      cache: {
        expires: Date.now() + settings.aivDataCache,
        key,
        data,
      },
    }).then(() => {
      // console.debug('data saved to card', key);
    });
  }
}
