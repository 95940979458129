// @flow
class Badge {
  icon: string;

  color: string;

  /**
   * Only for Card Detail Badges
   */
  title: string;

  text: string;

  refresh: string;

  constructor(data: Object) {
    this.text = data.text || '';
    this.icon = data.icon || null;
    this.color = data.color || '';
    this.title = data.title || '';
    this.refresh = data.refresh || '';
  }
}
export default Badge;
