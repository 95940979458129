/* eslint-disable object-shorthand */
/* eslint-disable func-names */
// @flow

import PopupSearch from './popup-search.model';
import { getApi } from '../shared/api-contact.service';
import { InlineResponse200, MauticContact } from '../api/mautic/src';
import { getStoredData } from '../shared/utils';
import { buildContactDisplayValues } from '../shared/contact.service';

export default class PopupSearchContact {
  static getPopup(t: any) {
    /**
     * Perform an action on the found and clicked contact
     * @param {Inlineresponse200} data
     */
    function searchContactSuccess(data: InlineResponse200) {
      const results = [];

      if (data.count <= 0) {
        return results;
      }

      const contacts = Object.values(data.contacts);

      contacts.forEach((contact: MauticContact) => {
        const display = buildContactDisplayValues(contact);
        results.push({
          text: display.label,
          callback: (trello: any) => {
            return getStoredData(t, 'mauticUrl').then((mauticUrl) => {
              trello.closePopup();
              return trello.attach({
                name: display.label,
                url: `${mauticUrl}/s/contacts/view/${contact.id}`,
              });
            });
          },
        });
      });

      results.push({
        alwaysVisible: true, // non-search option, always shown
        text: t.localizeKey('createContact'),
        callback: (trl) => {
          return getStoredData(trl, 'mauticUrl').then((mauticUrl) => {
            window.open(`${mauticUrl}/s/contacts/new`);
            trl.closePopup();
          });
        },
      });
      return results;
    }
    function searchContactCatch(error) {
      console.warn('Could not search contacts', error);
      return [];
    }

    const popup = new PopupSearch({
      title: t.localizeKey('mauticContacts'),
      items: function (trl, options) {
        return getApi(trl).then((api) => {
          if (!api) {
            t.alert({
              message: t.localizeKey('couldNotConnectMtc', {
                msg: t.localizeKey('checkConfig'),
              }),
              duration: 6,
              display: 'warning',
            });
            return [];
          }
          const { search } = options;
          const opts = {
            minimal: 1,
          };

          // only search if more than 3 chars are set
          if (search.length < 3) {
            return [];
          }

          return api
            .searchContact(search, 'XMLHttpRequest', opts)
            .then(searchContactSuccess)
            .catch(searchContactCatch);
        });
      },
      search: {
        count: 10, // number of items to display at a time
        placeholder: t.localizeKey('searchMtcContacts'),
        empty: t.localizeKey('noContactFound'),
        searching: t.localizeKey('searchingMtc'),
      },
    });

    return popup;
  }
}
