// @flow

/**
 * Trello Popup Search
 * @link https://developer.atlassian.com/cloud/trello/power-ups/ui-functions/popup/
 */
class PopupSearch {
  title: string;

  items: (t: any) => void;

  search: Object;

  constructor(data: Object) {
    this.title = data.title;
    this.items = data.items;
    this.search = data.search || {};
  }
}
export default PopupSearch;
