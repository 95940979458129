// @flow

import CardButton from './card-button.model';
import PopupSearchContact from '../popup-search/popup-search-contact';

export default class CardButtons {
  static icon: string;

  static init(t: any) {
    this.icon = './aivie-icon-ohne-rand.svg';

    function onBtnClick(trl, opt) {
      return trl.popup(PopupSearchContact.getPopup(t, opt));
    }

    const btn = new CardButton({
      // usually you will provide a callback function to be run on button click
      // we recommend that you use a popup on click generally
      icon: this.icon, // don't use a colored icon here
      text: t.localizeKey('addContact'),
      callback: onBtnClick,
      condition: 'edit',
    });

    return [btn.toObject()];
  }
}
