// @flow

// import MauticContactApi from '../api/mautic/src/ApiClient';
import { MauticContact } from '../api/mautic/src/index';
import Badge from './badge.model';
import fetchContactData from '../shared/api-contact.service';
import { getMauticIdForActiveCard, getRefreshInterval } from '../shared/utils';
import { renderDateLabel } from '../shared/contact.service';

export default class CardDetailBadges {
  static showBadge(command: string, type: string, prefs: Object) {
    if (command === 'card-badges') {
      return prefs[`${type}Front`] !== false;
    }
    if (command === 'card-detail-badges') {
      return prefs[`${type}Back`] !== false;
    }

    throw new Error('Unknown command');
  }

  static getTagsBadge(trello: any): Promise<Badge> {
    return getMauticIdForActiveCard(trello).then((mauticId) => {
      return fetchContactData(trello, mauticId).then((contact: MauticContact) => {
        if (!contact || !contact.tags) {
          // console.debug('no contact data: tags');
          return null;
        }
        let tagslist = '';
        contact.tags.forEach((tag) => {
          tagslist += `, ${tag.tag}`;
        });
        return new Badge({
          icon: './hashtag-icon-40w.png',
          title: trello.localizeKey('tags'),
          text: tagslist.substring(2),
          refresh: getRefreshInterval(),
        });
      });
    });
  }

  static getStageBadge(trello: any): Promise<Badge> {
    return getMauticIdForActiveCard(trello).then((mauticId) => {
      return fetchContactData(trello, mauticId).then((contact: MauticContact) => {
        if (!contact) {
          // console.debug('no contact data: stage');
          return null;
        }
        const { stage } = contact;
        if (!stage) {
          return null;
        }
        return new Badge({
          icon: './campaign-icon-40w.png',
          title: trello.localizeKey('stage'),
          text: stage.name || null,
          refresh: getRefreshInterval(),
        });
      });
    });
  }

  static getLastActiveBadge(trello: any): Promise<Badge> {
    return getMauticIdForActiveCard(trello).then((mauticId) => {
      return fetchContactData(trello, mauticId).then((contact: MauticContact) => {
        if (!contact) {
          // console.debug('no contact data: lastActive');
          return null;
        }
        const datestr = renderDateLabel(contact.lastActive);
        const color = datestr === 'Today' || datestr === 'Yesterday' ? 'orange' : null;
        return new Badge({
          title: trello.localizeKey('lastActive'),
          text: datestr,
          icon: './chart-icon-40w.png',
          color,
          refresh: getRefreshInterval(),
        });
      });
    });
  }

  static getPointsBadge(trello: any): Promise<Badge> {
    return getMauticIdForActiveCard(trello).then((mauticId) => {
      return fetchContactData(trello, mauticId).then((contactData: MauticContact) => {
        if (!contactData) {
          // console.debug('no contact data: points');
          return null;
        }
        const { points } = contactData;

        return new Badge({
          icon: './trophy-icon-40w.png',
          title: trello.localizeKey('points'),
          text: points.toString(),
          refresh: getRefreshInterval(),
        });
      });
    });
  }
}
