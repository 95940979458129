// @flow

// %%APP_ID%% is our opencontactmapp appid which we store in an environment variable
import { ApiClient, DefaultApi, InlineResponse200, MauticContact } from '../api/mautic/src/index';
import { getMauticAttachment, getStoredData } from './utils';
import { getCachedData, cacheToCard, clearCache } from './cache.service';
import { getToken, clearToken } from './auth.service';
import settings from './settings';

const { Promise } = window.TrelloPowerUp;

// we will hold onto and reuse promises based on the id of the card
const contactRequests = new Map();

function getCachedContactData(trello: any, contactId: number): Promise<MauticContact> {
  // clear cache if attachment for current card has been removed (only if in card context)
  if (trello.getContext().card) {
    trello
      .card('attachments')
      .then((card) => {
        if (!card.attachments || !getMauticAttachment(card.attachments)) {
          // this card doesn't have an attachment, let's clear anything we may have cached
          clearCache(trello);
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  }

  return getCachedData(trello, contactId).then((contactData: MauticContact) => {
    if (!contactData) {
      return null;
    }

    const contact = MauticContact.constructFromObject(contactData);
    // console.debug('using cached contact as MauticContact', contact);
    return contact;
  });
}
/**
 * Return the number of currently active requests to Mautic API
 */
function getNrOfRequests(): number {
  let count = 0;
  contactRequests.forEach((req: Promise) => {
    if (req.isPending()) {
      count += 1;
    }
  });
  return count;
}

/**
 * Delays if the nr of requests is above a certain number
 * @param {number} interval number of requests
 */
function delayRequests() {
  return new Promise((resolve) => {
    if (getNrOfRequests() <= settings.delay.maxRequests) {
      // console.debug('no delaying necessary', settings.delay);
      return resolve();
    }
    // delay it by FIFO
    return setTimeout(resolve, settings.delay.ms);
  });
}

/**
 * Get the OpenAPI default api
 * @param {Trello} t The Trello Object
 */
export function getApi(t: any): DefaultApi {
  return Promise.all([getToken(t), getStoredData(t, 'mauticUrl')])
    .then(([token, url]) => {
      if (!token.isValid() || !url) {
        // console.debug('no valid api token / config');
        return null;
      }

      const defaultClient = new ApiClient();
      defaultClient.basePath = url;
      const { oAuth2 } = defaultClient.authentications;
      oAuth2.accessToken = token.accessToken;

      return new DefaultApi(defaultClient);
    })
    .catch((error) => {
      //  Decryption failed. Message:\n at https://p.trellocdn.com/power-up.min.js:1:90426

      if (
        error instanceof Error &&
        error.message &&
        error.message.search('Decryption failed') >= 0
      ) {
        // 'can not get token'. Bug Trello?
        t.alert({
          message: t.localizeKey('couldNotGetToken'),
          duration: 6,
          display: 'warning',
        });
        clearToken(t);
        return null;
      }
      throw new Error(error.message);
    });
}

/**
 * Get Mautic contact data from cache or API
 * @param {TrelloCard} t Trello card
 */
export default function fetchContactData(t: any, contactId: number): Promise {
  if (!contactId || contactId <= 0) {
    // console.debug('no contact id');
    return null;
  }
  if (contactRequests.has(contactId)) {
    // we already have a request in progress for that contact, let's reuse that
    // console.debug('return existing request');
    return contactRequests.get(contactId);
  }

  const contactRequest = getCachedContactData(t, contactId).then((cache) => {
    function handleApiError(error) {
      contactRequests.delete(contactId);
      if (error.status && error.status === 404) {
        t.alert({
          message: t.localizeKey('mtcContactNotFound', { msg: error.message }),
          duration: 6,
          display: 'warning',
        });
      } else {
        console.warn('getContact API error', error);

        t.alert({
          message: t.localizeKey('couldNotConnectMtc', { msg: error.message }),
          duration: 6,
          display: 'warning',
        });
      }

      return null;
    }
    function getContactSuccess(data: InlineResponse200) {
      if (!data || !data.contact) {
        console.warn('no valid response', data);
        return null;
      }
      contactRequests.delete(contactId);

      const { contact } = data;

      // cache
      // const attachment = getMauticAttachment(attachments);
      // const { url } = attachment;

      cacheToCard(t, contactId, contact);
      return contact;
    }

    if (cache) {
      contactRequests.delete(contactId);
      // console.debug('return cached contact');
      return cache;
    }

    return Promise.all([getApi(t), delayRequests(t)])
      .then(([api]) => {
        if (!api) {
          // console.debug('No API found. Did you authorize your account?');
          return null;
        }
        // console.debug('requesting contact data', new Date());
        return api
          .getContact(contactId, 'XMLHttpRequest')
          .then(getContactSuccess)
          .catch(handleApiError);
      })
      .catch(handleApiError);
  });

  // store the outstanding request so it can be reused
  // console.debug(`store a new contact request for id: ${contactId}`, contactRequests);
  contactRequests.set(contactId, contactRequest);
  return contactRequest;
}
