// @flow

/**
 * Trello Card Button
 * @link https://developer.atlassian.com/cloud/trello/power-ups/capabilities/card-buttons/
 */
class CardButton {
  icon: string;

  callback: any;

  title: string;

  text: string;

  refresh: string;

  condition: string;

  url: string;

  target: string;

  constructor(data: Object) {
    this.text = data.text || '';
    this.icon = data.icon || undefined;
    this.callback = data.callback;
    this.condition = data.condition || undefined;
    this.url = data.url || undefined;
    this.target = data.target || undefined;
  }

  /**
   * Convert to standard Json object.
   * Workaround, since Trello can not handle the custom class Object
   */
  toObject(): Object {
    // workaround to keep callback
    const { callback } = this;
    const regularObject = JSON.parse(JSON.stringify(this));
    regularObject.callback = callback;
    return regularObject;
  }
}
export default CardButton;
