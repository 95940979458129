import { getMauticAttachment } from './shared/utils';
import CardDetailBadges from './card-detail-badges/card-detail-badges';
import { getAuthorizationStatus, logoutAndClearUser } from './shared/auth.service';
import Onboarding from './onboarding/onboarding';
import CardButtons from './card-buttons/card-buttons';
import localizationSettings from './shared/localizationSettings';
import settings from './shared/settings';
import CardFromUrl from './card-from-url/card-from-url';

const { Promise } = window.TrelloPowerUp;

const getContactBadges = (t) =>
  Promise.all([t.card('attachments'), t.get('board', 'shared')]).then(([card, prefs]) => {
    const att = getMauticAttachment(card.attachments);

    if (!card.attachments || !att || !att.url) {
      // if the card doesn't have a location at all, we won't show any badges
      return [];
    }

    if (!t.isMemberSignedIn()) {
      // only show Mautic data if user is logged in. Prevent showing on a public board.
      console.warn('Trello User not signed in');
      return [];
    }

    const tagsBadge = {
      dynamic: CardDetailBadges.getTagsBadge,
    };
    const stageBadge = {
      dynamic: CardDetailBadges.getStageBadge,
    };
    const lastActiveBadge = {
      dynamic: CardDetailBadges.getLastActiveBadge,
    };
    const pointsBadge = {
      dynamic: CardDetailBadges.getPointsBadge,
    };

    let badges = [];
    if (!prefs || typeof prefs !== 'object') {
      // default to all badges
      badges = [tagsBadge, stageBadge, pointsBadge, lastActiveBadge];
    } else {
      // there are some potential preferences
      [
        ['tags', tagsBadge],
        ['stage', stageBadge],
        ['points', pointsBadge],
        ['lastActive', lastActiveBadge],
      ].forEach(([type, badge]) => {
        if (CardDetailBadges.showBadge(t.getContext().command, type, prefs)) {
          badges.push(badge);
        }
      });
    }

    return badges;
  });

function getContactDetailBadges(t: any) {
  // show a board bar that informs the user that he should authorize
  getAuthorizationStatus(t).then((status) => {
    if (status.authorized === false) {
      t.boardBar({
        url: './onboarding/authorize.html',
        accentColor: settings.colors.primary,
        height: 252,
        resizable: true,
        title: t.localizeKey('authorizeAccount'),
      });
    }
  });

  return getContactBadges(t);
}
window.TrelloPowerUp.initialize(
  {
    // 'card-badges': getContactBadges,
    'card-detail-badges': getContactDetailBadges,
    'card-buttons': CardButtons.init,
    'card-from-url': CardFromUrl.init,
    'on-enable': Onboarding.init,
    'on-disable': logoutAndClearUser,
    'show-settings': (t) => {
      return t.popup({
        title: t.localizeKey('settings'),
        url: './settings/settings.html',
        height: 780,
      });
    },
    'show-authorization': (t) => {
      return t.modal({
        title: t.localizeKey('authorizeAccount'),
        url: './onboarding/authorize.html',
        accentColor: settings.colors.primary,
        height: 272,
        fullscreen: false,
      });
    },
    'authorization-status': getAuthorizationStatus,
    'remove-data': logoutAndClearUser,
    // card-from-url
    // format-url
    // list-sorters
    // list-actions
  },
  {
    localization: localizationSettings,
  }
);
