// @flow

import settings from '../shared/settings';

export default class Onboarding {
  static init(t: any) {
    return t.modal({
      url: '/onboarding/setup-api.html',
      // optional arguments to be passed to the iframe as query parameters
      // access later with t.arg('text')
      // args: { conf },
      // optional color for header chrome
      accentColor: settings.colors.primary,
      height: 720,
      fullscreen: false,
      title: t.localizeKey('connectMtcToTrello'),
    });
  }
}
